import React from 'react';

import { notifyException } from 'utils/errorReporter';

import InternalError from '../internalError';

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: Error) {
    notifyException(error);
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <InternalError /> : children;
  }
}

export default ErrorBoundary;
