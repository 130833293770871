import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'radiance-ui/lib/constants';

type MediaQueriesType = keyof typeof MEDIA_QUERIES;

export const mobileOnly = (
  displayType = 'initial',
  breakpoint: MediaQueriesType = 'mdUp',
) => css`
  display: ${displayType};

  ${MEDIA_QUERIES[breakpoint]} {
    display: none;
  }
`;

export const desktopOnly = (
  displayType = 'initial',
  breakpoint: MediaQueriesType = 'mdUp',
) => css`
  display: none;

  ${MEDIA_QUERIES[breakpoint]} {
    display: ${displayType};
  }
`;

export const ResponsiveText = styled.span<{ desktop?: boolean }>`
  ${({ desktop = false }) =>
    desktop ? desktopOnly('inline') : mobileOnly('inline')};
`;
