/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import handlePageAnalytics from 'utils/analyticsHelper';

export const onClientEntry = async () => {
  // Intersection Observer Polyfill
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  handlePageAnalytics(location, prevLocation);
};
