import { useEffect, useState } from 'react';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';

import config from 'consts/config';
import COOKIE_KEYS from 'consts/cookieKeys';
import { IS_BUILD_OR_TEST, IS_PRODUCTION } from 'consts/environmental';
import type { FlagValue } from 'consts/featureFlagsAndMetrics';
import { LD_FLAGS } from 'consts/featureFlagsAndMetrics';
import cookie from 'utils/cookie';
import { getQueryParameterValue } from 'utils/queryParameters';
import { getSingletonUuid } from 'utils/uuid';

// Notion doc: https://www.notion.so/curology/How-To-Use-LaunchDarkly-Feature-Flags-951fa6eb639d4ceca0a3cfa346d1179c

let ldClient: LaunchDarkly.LDClient;
let ldClientReady = false;
const onReadyCallbacks: { (): void }[] = [];

const getLdUuidAndSetLdUuidCookie = () => {
  const ldUuidCookieSet = cookie.has(COOKIE_KEYS.ldUuid);
  let ldUuid;

  if (ldUuidCookieSet) {
    ldUuid = cookie.get(COOKIE_KEYS.ldUuid);
  } else {
    ldUuid = getSingletonUuid();
  }

  if (!ldUuidCookieSet && ldUuid) {
    cookie.set(COOKIE_KEYS.ldUuid, ldUuid, { expires: 395 });
  }

  return ldUuid;
};

export const initLd = () => {
  const clientSideId = config.LD_CLIENT_SIDE_ID;

  if (clientSideId) {
    const ldUuid = getLdUuidAndSetLdUuidCookie();

    const context = {
      kind: 'user',
      key: ldUuid,
    };

    ldClient = LaunchDarkly.initialize(clientSideId, context, {
      bootstrap: 'localStorage',
    });

    ldClient.on('ready', () => {
      ldClientReady = true;

      onReadyCallbacks.forEach((onReadyCallback) => {
        onReadyCallback();
      });
    });

    ldClient.on('change', () => {
      // Implement change workflow here if needed.
    });
  }
};

/**
 * There are two possible ways to override a LaunchDarkly flag value in a development environment.
 *
 *  1. Add a query parameter to your request with the flag name and override value. Example: for
 *      a flag named login, add `?login=true`
 *  2. Set the `value` of the LaunchDarkly flag to anything other than undefined in `consts/featureFlagsAndMetrics`
 *
 *  Note that a query param will override a value specified in `consts/featureFlagsAndMetrics`
 *
 *  Will always return undefined in production, during tests, and during static builds
 *
 * @param flagName
 */
const getFlagOverride = (flagName: string): FlagValue => {
  if (IS_PRODUCTION || IS_BUILD_OR_TEST) {
    return undefined;
  }

  return getQueryParameterValue(flagName) ?? LD_FLAGS[flagName];
};

export const useFlags = () => {
  const [flags, setFlags] = useState<LaunchDarkly.LDFlagSet>({});

  useEffect(() => {
    if (ldClientReady) {
      setFlags(ldClient.allFlags());
    } else {
      onReadyCallbacks.push(() => {
        setFlags(ldClient.allFlags());
      });
    }
  }, []);

  return flags;
};

export const useFlag = (flagName?: string) => {
  const [flagValue, setFlagValue] = useState<FlagValue>();
  const flags = useFlags();

  useEffect(() => {
    if (flagName) {
      const newFlagValue = getFlagOverride(flagName) ?? flags[flagName];
      setFlagValue(newFlagValue);
    }
  }, [flags]);

  return flagValue;
};

export const trackCustomFeatureFlagEvent = (
  eventName: string,
  data?: {},
) => {
  if (ldClientReady) {
    ldClient.track(eventName, data);
  } else {
    onReadyCallbacks.push(() => {
      ldClient.track(eventName, data);
    });
  }
};
