import cookie from 'utils/cookie';
import COOKIE_KEYS from 'consts/cookieKeys';
import CONFIG from 'consts/config';
import DOMAIN_OPTIONS from 'consts/domainOptions';
import { IS_PRODUCTION } from 'consts/environmental';

const PAGE_URLS = {
  baseUrl: CONFIG.SITE_URL,
  landingPage: () => {
    const variant = cookie.get(COOKIE_KEYS.lpVariant);

    if (
      IS_PRODUCTION &&
      typeof window !== `undefined` &&
      window.location.hostname === DOMAIN_OPTIONS.AGENCY_ADSAFE
    ) {
      return '/free-trial/1g';
    }

    return variant ? `/free-trial/${variant}` : '/';
  },

  contact: '/contact-us',
  doNotSell: '/donotsell',
  marketingSafeLandingPage: '/free-trial/1g',
  ourStory: '/our-story',

  // BELOW ending slash is needed for activeLink style to work
  disclaimer: '/disclaimer/',
  guardianTelehealthConsent: '/guardian-telehealth-consent/',
  privacy: '/privacy/',
  telehealthConsent: '/telehealth-consent/',
  terms: '/terms/',
};

export default PAGE_URLS;
