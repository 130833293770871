import React from 'react';
import { Button } from 'radiance-ui';

import PAGE_URLS from 'consts/pageUrls';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { navInternalWithQueryString } from 'utils/nav';

import backgroundDesktop from './images/backgroundDesktop.jpg';
import backgroundDesktop2x from './images/backgroundDesktop@2x.jpg';
import backgroundMobile from './images/backgroundMobile.jpg';
import backgroundMobile2x from './images/backgroundMobile@2x.jpg';
import {
  ContentBox,
  descriptionStyles,
  imgStyles,
  InnerContainer,
  headerStyles,
  OuterContainer,
} from './style';

const InternalError = () => {
  const onClick = () => {
    navInternalWithQueryString(PAGE_URLS.landingPage());
  };

  return (
    <OuterContainer>
      <InnerContainer>
        <ContentBox>
          <h2 css={headerStyles}>
            Oops! Something
            <br />
            went wrong.
          </h2>
          <p css={descriptionStyles}>We’re working on it.</p>
          <Button onClick={onClick}>Go to Home</Button>
        </ContentBox>
        <img
          css={[desktopOnly('initial', 'lgUp'), imgStyles]}
          srcSet={`${backgroundDesktop2x} 2x`}
          src={backgroundDesktop}
          alt="Future formula bottle on stack of papers"
        />
        <img
          css={[mobileOnly('initial', 'lgUp'), imgStyles]}
          srcSet={`${backgroundMobile2x} 2x`}
          src={backgroundMobile}
          alt="Future formula bottle on stack of papers"
        />
      </InnerContainer>
    </OuterContainer>
  );
};

export default InternalError;
