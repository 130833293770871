import CONFIG from 'consts/config';

const BASE_URL = CONFIG.APP_URL;

export default {
  baseUrl: BASE_URL,
  login: `${BASE_URL}/auth/login`,
  welcome: `${BASE_URL}/auth/welcome`,
  register: `${BASE_URL}/auth/welcome/register`,
  requestMedicalRecord: `${BASE_URL}/app/dashboard/request-medical-record`,
  dashboardPlans: `${BASE_URL}/dashboard/account/plan`,
  trackingOptOut: `${BASE_URL}/user/tracking/opt-out`,
  api: {
    userReferrerUtm: `${BASE_URL}/api/user-referrer-utm`,
  },
};
