import Cookies from 'js-cookie';

import { getCookieDomain } from 'consts/config';
import { notifyException } from 'utils/errorReporter';

const MAX_COOKIE_BYTES = 4096;

const stringByteSize = (string: string) =>
  encodeURI(string).split(/%(?:u[0-9A-F]{2})?[0-9A-F]{2}|./).length - 1;

const get = (key: string) => Cookies.get(key);

const set = (
  key: string,
  value: string,
  options: Cookies.CookieAttributes = {},
) => {
  const currentCookieBytes = stringByteSize(document.cookie);
  const newCookieBytes = stringByteSize(`${key}=${value}`);

  if (currentCookieBytes + newCookieBytes > MAX_COOKIE_BYTES) {
    notifyException(Error('Cookie overflow'));
    return;
  }

  const cookieOptions = {
    domain: getCookieDomain(),
    expires: 90,
    ...options,
  };

  return Cookies.set(key, value, cookieOptions);
};

const remove = (key: string) =>
  Cookies.remove(key, { domain: getCookieDomain() });

const has = (key: string) => !!get(key);

export default {
  get,
  has,
  remove,
  set,
};
