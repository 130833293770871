import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SPACER, MEDIA_QUERIES, Z_SCALE } from 'radiance-ui/lib/constants';

export const ContentBox = styled.div`
  padding: 4.5rem 0 ${SPACER.x2large};
  z-index: ${Z_SCALE.e2};

  ${MEDIA_QUERIES.lgUp} {
    padding: 11rem 0 14rem;
    align-self: flex-start;
  }
`;

export const descriptionStyles = css`
  margin: ${SPACER.large} 0;
  max-width: 23rem;

  ${MEDIA_QUERIES.lgUp} {
    margin: ${SPACER.xlarge} 0;
  }
`;

export const imgStyles = css`
  width: 80vw;
  max-width: 430px;
  height: auto;
  margin-right: -${SPACER.large};
  align-self: flex-end;

  ${MEDIA_QUERIES.lgUp} {
    width: 60vw;
    max-width: 700px;
    margin-left: -${SPACER.x2large};
  }
`;

export const InnerContainer = styled.div`
  padding: 0 ${SPACER.large};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  height: calc(100vh - 64px);
  align-items: center;
  min-height: 775px;

  ${MEDIA_QUERIES.lgUp} {
    flex-direction: row;
    align-items: flex-end;
    max-width: 1280px;
    margin: auto;
    padding: 0 4.5rem;
    height: calc(100vh - 80px);
  }
`;

export const headerStyles = css`
  ${MEDIA_QUERIES.lgUp} {
    min-width: 464px;
  }
`;

export const OuterContainer = styled.div`
  background-color: rgb(208, 207, 205);

  ${MEDIA_QUERIES.lgUp} {
    background-color: rgb(209, 205, 204);
  }
`;
