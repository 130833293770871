// Use a flag value other than undefined here to override for local testing (see src/utils/featureFlagsAndMetrics::getFlagOverride)
export type FlagValue = string | boolean | number | null | undefined;

interface LaunchDarklyFlag {
  [key: string]: FlagValue;
}

export const LD_FLAGS: LaunchDarklyFlag = {
  'test-flag': undefined,
};
