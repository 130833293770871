import canonical from '@segment/canonical';
import url from 'component-url';
import { dropTrailingSlash } from 'great-gatsby/utils';

import EVENTS from 'consts/metricsTrackingEvents';
import { FlagValue } from 'consts/featureFlagsAndMetrics';

import { trackCustomFeatureFlagEvent } from './featureFlagsAndMetrics';

export const trackEvent = (event: string, properties = {}) => {
  if (!window || !window.dataLayer) {
    return;
  }
  // Reset the dataLayer so properties are not recursively merged with
  // the properties of any events that were pushed previously.
  // See https://github.com/google/data-layer-helper
  window.dataLayer.push({ event: 'Reset DataLayer', properties: null });
  window.dataLayer.push({ event, properties });
  trackCustomFeatureFlagEvent(event, properties);
};

interface IdentifyUserOptions {
  patient: Record<string, unknown>;
  traits: Record<string, unknown>;
  callback: () => void;
}

export const identifyUser = ({
  patient,
  callback,
  traits = {},
}: IdentifyUserOptions) => {
  if (!window || !window.dataLayer) {
    return;
  }

  window.dataLayer.push({ event: 'Identify', patient, traits });

  if (typeof callback === 'function') {
    setTimeout(callback, 300);
  }
};

const canonicalPath = () => {
  const canon = canonical();

  if (!canon) {
    return window.location.pathname;
  }

  const parsed = url.parse(canon);
  return parsed.pathname.length === 1
    ? parsed.pathname
    : dropTrailingSlash(parsed.pathname);
};

const canonicalUrl = (search: string) => {
  const canon = canonical();

  if (canon) {
    return canon.indexOf('?') !== -1 ? canon : canon + search;
  }

  const uri = window.location.href;
  const index = uri.indexOf('#');
  return index === -1 ? uri : uri.slice(0, index);
};

// see https://segment.com/docs/spec/page/#properties
// and https://github.com/segmentio/analytics.js-core/blob/2facc5f327b84513ebbede2e5392483ea654f117/lib/pageDefaults.js
const pageDefaults = () => ({
  path: canonicalPath(),
  referrer: document.referrer,
  search: window.location.search,
  url: canonicalUrl(window.location.search),
  host: window.location.hostname,
});

export const trackPage = (properties = {}) => {
  trackEvent(EVENTS.loadedAPage, { ...pageDefaults(), ...properties });
};

export const trackFeatureFlagValue = (
  flagName: string,
  flagValue: FlagValue,
) => {
  trackEvent(EVENTS.launchDarklyFlagAssigned, {
    flagName,
    flagValue,
  });
};
