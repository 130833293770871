import React from 'react';
import { Global, css } from '@emotion/react';
import { MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

const GlobalTypography = () => (
  <Global
    styles={(theme) => css`
      body {
        font-family: 'GreyLLWeb-Book', 'Avant Garde', Avantgarde,
          'Century Gothic', CenturyGothic, AppleGothic, Verdana, sans-serif;
      }

      h1,
      h2,
      h3,
      h4 {
        font-family: ${theme.FONTS.headerFont};
        font-weight: 400;
        i,
        em {
          font-family: 'quadrant-text-italic';
          font-style: normal;
        }
        strong {
          font-family: ${theme.FONTS.headerFont};
          font-weight: bold;
        }
      }

      h1 {
        font-size: 1.7rem;
        line-height: 1.2;
        @media screen and (min-width: 375px) {
          font-size: 2.375rem; //38px
        }
        ${MEDIA_QUERIES.lgUp} {
          font-size: 4.25rem; //68px
          line-height: 1.1;
        }
      }

      h2 {
        line-height: 1.2;
        font-size: 1.7rem;
        @media screen and (min-width: 375px) {
          font-size: 2rem; //32px
        }
        ${MEDIA_QUERIES.lgUp} {
          font-size: 3.375rem; //54px
          line-height: 1.2;
        }
        ${MEDIA_QUERIES.xxlUp} {
          font-size: 3.5rem; //56px
          line-height: 1.2;
        }
      }

      h3 {
        font-size: 1.75rem; //28px
        line-height: 1.3;
        ${MEDIA_QUERIES.lgUp} {
          font-size: 2.25rem; //36px
          line-height: 1.2;
        }
        ${MEDIA_QUERIES.xxlUp} {
          font-size: 2.375rem; //38px
          line-height: 1.3;
        }
      }

      h4 {
        line-height: 1.2;
        font-size: 1.05rem;
        @media screen and (min-width: 375px) {
          font-size: 1.25rem; //20px
        }
        ${MEDIA_QUERIES.lgUp} {
          font-size: 1.375rem; //22px
        }
        ${MEDIA_QUERIES.xxlUp} {
          line-height: 1.3;
        }
      }

      h5 {
        font-size: 0.875rem; //14px
        line-height: 1.1;
        font-weight: 400;
      }

      p {
        font-size: 0.85rem;
        line-height: 1.5;
        font-family: ${theme.FONTS.headerFont};
        @media screen and (min-width: 375px) {
          font-size: 1rem;
        }
        i,
        em {
          font-family: 'quadrant-text-italic';
          font-style: normal;
        }
        strong {
          font-family: ${theme.FONTS.headerFont};
          font-weight: bold;
        }
      }

      .body2,
      .smallP {
        font-size: 0.875rem; //14px
        line-height: 1.6;
        font-family: ${theme.FONTS.headerFont};
      }

      strong {
        font-family: 'GreyLLWeb-Medium';
        font-weight: normal;
      }

      i,
      em {
        font-family: 'GreyLLWeb-BookItalic';
        font-style: normal;
      }

      small {
        ${TYPOGRAPHY_STYLE.caption(theme)};
        font-size: 0.75rem; //12px
        line-height: 1.2;
        display: block;
      }
    `}
  />
);

export default GlobalTypography;
