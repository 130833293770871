const COOKIE_KEYS = {
  auth: 'hasAuth',
  curologyUuid: 'curologyUuid',
  invite: 'inviteToken',
  lpVariant: 'landingPageVariant',
  lpUrl: 'landingPageUrl',
  referrerUrl: 'httpReferrerUrl',
  hasTrackingOptedOut: 'trackingOptOut',
  hasClosedCtaBar: 'hasClosedCtaBar',
  hasClosedVanityBanner: 'hasClosedVanityBanner',
  experiment: 'rivalExperiment',
  offer: 'offer',
  ldUuid: 'ldUuid',
};

export default COOKIE_KEYS;
