import { css } from '@emotion/react';

export default css`
  @font-face {
    font-family: 'quadrant-text';
    src: url('https://assets.curology.com/fonts/quadrant-text/QuadrantText-Regular.woff2')
      format('woff2');
  }

  @font-face {
    font-family: 'quadrant-text-italic';
    src: url('https://assets.curology.com/fonts/quadrant-text/QuadrantText-RegularItalic.woff2')
      format('woff2');
  }

  @font-face {
    font-family: 'quadrant-text-mono';
    src: url('https://assets.curology.com/fonts/quadrant-mono/QuadrantTextMono-Regular.woff2')
      format('woff2');
  }

  @font-face {
    font-family: 'quadrant-text-mono-italic';
    src: url('https://assets.curology.com/fonts/quadrant-mono/QuadrantTextMono-RegularItalic.woff2')
      format('woff2');
  }
`;
