/*
 * This layout is automatically applied to every page
 * by 'gatsby-plugin-layout'. Cookies are not handled
 * correctly and ErrorBoundary does not catch errors
 * consistently without plugin.
 * https://www.gatsbyjs.org/packages/gatsby-plugin-layout/
 */
import React, { useEffect, useState } from 'react';
import { ThemeProvider, Global } from '@emotion/react';
import { secondaryTheme } from 'radiance-ui/lib/constants';
import { BrandStyles, ResetStyles } from 'radiance-ui';

import fonts from 'styles/fonts';
import GlobalTypography from 'styles/global-typography';
import ErrorBoundary from 'components/global/errors/errorBoundary';
import { captureReferrerUrl } from 'utils/captureReferrer';
import { CYPRESS_DEFINED } from 'consts/environmental';
import { initLd } from 'utils/featureFlagsAndMetrics';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const [isCypressAndClient, setIsCypressAndClient] = useState(false);

  useEffect(() => {
    initLd();
    captureReferrerUrl();

    if (CYPRESS_DEFINED) {
      setIsCypressAndClient(true);
    }
  }, []);

  return (
    <ThemeProvider theme={secondaryTheme}>
      {isCypressAndClient && <div id="client_ready" />}
      <Global styles={fonts} />
      <link
        rel="stylesheet"
        href="https://assets.curology.com/fonts/grey-ll/grey-ll.css"
        crossOrigin="true"
      />
      <BrandStyles />
      <ResetStyles />
      <GlobalTypography />
      <ErrorBoundary>{children}</ErrorBoundary>
    </ThemeProvider>
  );
};

export default Layout;
