import { navigate } from 'gatsby';
import { generateQueryString, parseQueryString } from 'great-gatsby/utils';

import CONFIG from 'consts/config';
import COOKIE_KEYS from 'consts/cookieKeys';
import cookie from 'utils/cookie';

const isMarketingSafeDomain = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const { host } = window.location;
  return host === CONFIG.MARKETING_SAFE_DOMAIN;
};

export const navInternalWithQueryString = (href: string) => {
  navigate(href);
};

const getAmplitudeDeviceQueryString = () => {
  const deviceId =
    typeof window !== 'undefined' &&
    window.amplitude &&
    window.amplitude.getInstance() &&
    window.amplitude.getInstance().options &&
    window.amplitude.getInstance().options.deviceId;

  return deviceId ? { amp_device_id: deviceId } : {};
};

export const navExternalWithQueryString = (href: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  let newQueryString = {};

  if (isMarketingSafeDomain()) {
    newQueryString = { ...parseQueryString(window.location) };
  }

  if (!cookie.has(COOKIE_KEYS.hasTrackingOptedOut)) {
    newQueryString = { ...newQueryString, ...getAmplitudeDeviceQueryString() };
  }

  window.location.href = `${href}${generateQueryString(newQueryString)}`;
};
