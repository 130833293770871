import * as Sentry from '@sentry/gatsby';

import { LOGGING_ENABLED } from 'consts/environmental';

/**
 * Use for error states
 */
export const notifyException = (
  ...args: Parameters<typeof Sentry.captureException>
) => {
  if (LOGGING_ENABLED) {
    return Sentry.captureException(...args);
  }
  throw args[0];
};

/**
 * Use for messages/data we want to log as issues, but not necessarily error states.
 */
export const notify = (
  ...args: Parameters<typeof Sentry.captureMessage>
): void => {
  if (LOGGING_ENABLED) {
    Sentry.captureMessage(...args);
  } else {
    // eslint-disable-next-line no-console
    console.warn(args[0]);
  }
};

export const setUser = (user: { id: number | string }) => {
  if (!LOGGING_ENABLED) return;
  Sentry.setUser({ id: user.id.toString() });
};
