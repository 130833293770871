const EVENTS = {
  ctaClicked: 'CTA clicked',
  experimentLoaded: 'Experiment Loaded',
  experimentLoadedSSR: 'Experiment Loaded SSR', // TODO verify amplitude name with data team
  launchDarklyFlagAssigned: 'Launch Darkly Flag Assigned',
  loadedAPage: 'Loaded a Page',
  loginClicked: 'Login clicked',
  moduleInteraction: 'Module Interaction',
  moduleViewed: 'Module Viewed',
  offerCookieSet: 'Offer Cookie Set',
  offerCookieUnassign: 'Offer Cookie Unassign',
};

export default EVENTS;
