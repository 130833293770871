import { getDomainFromHostname } from 'utils/string';
import { IS_PREVIEW, CYPRESS_DEFINED } from 'consts/environmental';

export const getCookieDomain = () => {
  if (IS_PREVIEW) {
    return window.location.hostname;
  }
  return `.${getDomainFromHostname(window.location.hostname)}`;
};

const config = {
  APP_ENV: process.env.APP_ENV,
  APP_URL: process.env.APP_URL,
  FACEBOOK_BP_PIXEL_ID: process.env.FACEBOOK_BP_PIXEL_ID,
  GTM_KEY: process.env.GTM_KEY,
  GTM_DEV_KEY: process.env.GTM_DEV_KEY,
  LD_CLIENT_SIDE_ID: process.env.LD_CLIENT_SIDE_ID,
  MARKETING_SAFE_DOMAIN: 'withagency.co',
  SITE_URL: process.env.SITE_URL,
};

if (CYPRESS_DEFINED) {
  config.GTM_KEY = '';
  config.GTM_DEV_KEY = '';
}

export default config;
