import axios from 'axios';
import utm from '@segment/utm-params';
import { v4 } from 'uuid';

import { IS_PRODUCTION } from 'consts/environmental';
import COOKIE_KEYS from 'consts/cookieKeys';
import APP_URLS from 'consts/appUrls';
import cookie from 'utils/cookie';
import { notify } from 'utils/errorReporter';

const hasValidUtmParams = () => {
  const { source, medium, name } = utm(window.location.search);

  return [source, medium, name].filter((utmParam) => !!utmParam).length > 1;
};

const reportReferrerUtm = () => {
  if (!cookie.has(COOKIE_KEYS.curologyUuid)) {
    const uuid = v4();
    cookie.set(COOKIE_KEYS.curologyUuid, uuid, { expires: 395 });
  }

  if (
    IS_PRODUCTION &&
    cookie.has(COOKIE_KEYS.curologyUuid) &&
    (hasValidUtmParams() || document.referrer)
  ) {
    axios
      .post(APP_URLS.api.userReferrerUtm, {
        uuid: cookie.get(COOKIE_KEYS.curologyUuid),
        landing_page_url: window.location.href,
        referrer_url: document.referrer,
        user_agent: (!!window.navigator && window.navigator.userAgent) || '',
      })
      .catch((_error) => {
        notify('Failed to report UTM/Referrer', 'warning');
      });
  }
};

export default reportReferrerUtm;
