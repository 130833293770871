import Visibility from 'visibilityjs';
import utm from '@segment/utm-params';
import { WindowLocation } from '@gatsbyjs/reach-router';
import { injectGTM } from 'great-gatsby/utils';

import CONFIG from 'consts/config';
import { IS_PRODUCTION } from 'consts/environmental';

import reportReferrerUtm from './reportReferrerUtm';
import { trackPage } from './metricsTracking';

/** initialize production analytics */
const init = (preRender: boolean) => {
  reportReferrerUtm();
  setTimeout(() => {
    injectGTM(CONFIG.GTM_KEY as string);
  }, 500);
  setTimeout(() => trackPage({ preRender }), 500);
};

/** initialize analytics after a user has interacted on the page */
const initAfterUserInteraction = (preRender: boolean) => {
  const report = () => {
    init(preRender);
    document.removeEventListener('mousemove', report);
    document.removeEventListener('touchstart', report);
  };

  // Mousemove for desktop, touchstart for mobile / touch screen devices
  document.addEventListener('mousemove', report);
  document.addEventListener('touchstart', report);
};

/**
 * initialize dev analytics
 *
 * @param {boolean} isDebug if true, inject the production GTM container
 */
const initDev = (isDebug = false) => {
  const key = (isDebug ? CONFIG.GTM_KEY : CONFIG.GTM_DEV_KEY) as string;
  reportReferrerUtm();
  setTimeout(() => {
    injectGTM(key);
  }, 500);
  setTimeout(() => trackPage(), 500);
};

const initializeAnalytics = (location: WindowLocation) => {
  if (!IS_PRODUCTION) {
    // add `?gtm_prod` onto the gatsby url in dev to include
    // the production GTM container for debugging purposes
    const isDebug = location.search.includes('gtm_prod');
    initDev(isDebug);
    return;
  }

  const isSnapchat = utm(location.href).source === 'snapchat';
  const preRender = Visibility.hidden();

  if (isSnapchat) {
    // snapchat ads will preload the site,
    // initialize only after page interaction
    initAfterUserInteraction(preRender);
  } else if (preRender) {
    // initialize after the page is being viewed
    Visibility.onVisible(() => init(preRender));
  } else {
    init(preRender);
  }
};

let hasInitialized = false;
const handlePageAnalytics = (
  location: WindowLocation,
  prevLocation?: WindowLocation,
) => {
  // check for first page view or initial redirect (eg: vanity)
  const shouldInitialize =
    !hasInitialized && (!prevLocation || prevLocation.key === 'initial');

  if (shouldInitialize) {
    initializeAnalytics(location);
    hasInitialized = true;
  } else {
    const key = (IS_PRODUCTION ? CONFIG.GTM_KEY : CONFIG.GTM_DEV_KEY) as string;
    setTimeout(() => {
      injectGTM(key);
    }, 500);
    trackPage({ preRender: false });
  }
};

export default handlePageAnalytics;
