import cookie from 'utils/cookie';
import COOKIE_KEYS from 'consts/cookieKeys';

export const captureLandingPageUrl = () => {
  if (!cookie.has(COOKIE_KEYS.lpUrl)) {
    // take first 1000 characters to limit cookie overflow
    const landingPageUrl = window.location.href.substring(0, 1001);
    cookie.set(COOKIE_KEYS.lpUrl, landingPageUrl);
  }
};

export const captureReferrerUrl = () => {
  if (!cookie.has(COOKIE_KEYS.referrerUrl)) {
    // take first 1000 characters to limit cookie overflow
    const referrerUrl = document.referrer.substring(0, 1001);
    cookie.set(COOKIE_KEYS.referrerUrl, referrerUrl);
  }
};
